<template>
  <div class="enterprise-stats">
    <div class="begin flex items-center">
      <div class="stats rounded-xl shadow bg-white pt-8 pb-6 px-6 mr-3 w-1/4">
        <div class="number font-medium text-3xl">{{ allAgents.length }}</div>
        <div class="name uppercase text-xl">{{ $t('agentsMenu') }}</div>
        <div class="service-stat flex items-center mt-4">
          <carousel ref="carousel" :items-to-show="2" :wrap-around="false">
            <slide v-for="slide in agentStat" :key="slide">
              <div
                class="carousel__item in-service rounded-lg py-1 px-3 mr-2 w-full font-light lowercase text-sm"
                :style="{ backgroundColor: slide.background, color: slide.color }"
              >
                {{ slide.number + ' ' + slide.title }}
              </div>
            </slide>
          </carousel>
          <div class="icon cursor-pointer ml-2" @click="goNext">
            <icon :data="icons.iconNext" original with="10" height="10"/>
          </div>
        </div>
      </div>
      <div class="stats rounded-xl shadow bg-white pt-8 pb-6 px-6 mx-3 w-1/4">
        <div class="number font-medium text-3xl">{{ allControllers.length }}</div>
        <div class="name uppercase text-xl">{{ $t('supervisors') }}</div>
        <div class="service-stat flex items-center mt-4">
          <carousel ref="carousels" :items-to-show="2" :wrap-around="false">
            <slide v-for="slide in controllerStat" :key="slide">
              <div
                class="carousel__item in-service rounded-lg py-1 px-3 mr-2 w-full font-light lowercase text-sm"
                :style="{ backgroundColor: slide.background, color: slide.color }"
              >
                {{ slide.number + ' ' + slide.title }}
              </div>
            </slide>
          </carousel>
          <div class="icon cursor-pointer ml-2" @click="goNextSecond">
            <icon :data="icons.iconNext" original with="10" height="10"/>
          </div>
        </div>
      </div>
      <div class="stats rounded-xl shadow bg-white pt-8 pb-6 px-6 mx-3 w-1/4">
        <div class="number font-medium text-3xl">{{ allPersonal.length }}</div>
        <div class="name uppercase text-xl">{{ $t('agentsPersonalMenu') }}</div>
        <div class="service-stat flex items-center mt-4">
          <carousel ref="carouselss" :items-to-show="2" :wrap-around="false">
            <slide v-for="slide in controllerStat" :key="slide">
              <div
                class="carousel__item in-service rounded-lg py-1 px-3 mr-2 w-full font-light lowercase text-sm"
                :style="{ backgroundColor: slide.background, color: slide.color }"
              >
                {{ slide.number + ' ' + slide.title }}
              </div>
            </slide>
          </carousel>
          <div class="icon cursor-pointer ml-2" @click="goNextThird">
            <icon :data="icons.iconNext" original with="10" height="10"/>
          </div>
        </div>
      </div>
      <div class="stats rounded-xl shadow bg-white pt-8 pb-6 px-6 ml-3 w-1/4">
        <div class="number font-medium text-3xl flex items-center justify-between">
          <div class="is-number">{{ deployedEquipments }}</div>
          <div class="flex items-center hidden">
            <div class="percent">+ 1%</div>
            <div class="icon-top ml-4">
              <icon :data="icons.iconTop" original width="15" height="25"/>
            </div>
          </div>
        </div>
        <div class="name uppercase text-xl mb-4" v-html="$t('deployedEquipment')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import iconNext from '../../../assets/icons/ic_right.svg'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import iconTop from '../../../assets/icons/ic_arrow_top.svg'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'enterprise-stats',
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      icons: { iconNext, iconTop },
      agentStat: [
        {
          number: 0,
          title: this.$t('inService'),
          color: '#0AE76F',
          background: 'rgba(10, 231, 111, 0.15)'
        },
        {
          number: 0,
          title: this.$t('onLeave'),
          color: '#FBA705',
          background: 'rgba(251, 167, 5, 0.15)'
        },
        {
          number: 0,
          title: this.$t('suspended') + 's',
          color: '#4F4F4F',
          background: 'rgba(79, 79, 79, 0.15)'
        }
      ],
      controllerStat: [
        {
          number: 0,
          title: this.$t('inService'),
          color: '#0AE76F',
          background: 'rgba(10, 231, 111, 0.15)'
        },
        {
          number: 0,
          title: this.$t('onLeave'),
          color: '#FBA705',
          background: 'rgba(251, 167, 5, 0.15)'
        },
        {
          number: 0,
          title: this.$t('suspended') + 's',
          color: '#4F4F4F',
          background: 'rgba(79, 79, 79, 0.15)'
        }
      ],
      personalStat: [
        {
          number: 0,
          title: this.$t('inService'),
          color: '#0AE76F',
          background: 'rgba(10, 231, 111, 0.15)'
        },
        {
          number: 0,
          title: this.$t('onLeave'),
          color: '#FBA705',
          background: 'rgba(251, 167, 5, 0.15)'
        },
        {
          number: 0,
          title: this.$t('suspended') + 's',
          color: '#4F4F4F',
          background: 'rgba(79, 79, 79, 0.15)'
        }
      ],
      allAgents: [],
      allControllers: [],
      deployedEquipments: 0,
      allPersonal: []
    }
  },
  created () {
    this.getAgentStats()
    this.getControllerStats()
    this.getDeployedEquipmentStats()
    this.getPersonalStats()
  },
  methods: {
    numberAgentInService: async function () { // GET NUMBER AGENT IN SERVICE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'agent'),
        where('status', '==', 'inService')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.agentStat[0].number = table.length
      })
      return this.agentStat[0].number
    },
    numberAgentOnLeave: async function () { // GET NUMBER AGENT ON LEAVE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'agent'),
        where('status', '==', 'onVacation')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.agentStat[1].number = table.length
      })
      return this.agentStat[1].number
    },
    numberAgentSuspended: async function () { // GET NUMBER AGENT SUSPENDED
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'agent'),
        where('status', '==', 'suspended')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.agentStat[2].number = table.length
      })
      return this.agentStat[2].number
    },
    numberControllerInService: async function () { // GET NUMBER CONTROLLER IN SERVICE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'supervisor'),
        where('status', '==', 'inService')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.controllerStat[0].number = table.length
      })
      return this.controllerStat[0].number
    },
    numberControllerOnLeave: async function () { // GET NUMBER CONTROLLER ON LEAVE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'supervisor'),
        where('status', '==', 'onVacation')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.controllerStat[1].number = table.length
      })
      return this.controllerStat[1].number
    },
    numberControllerSuspended: async function () { // GET NUMBER CONTROLLER SUSPENDED
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'supervisor'),
        where('status', '==', 'suspended')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.controllerStat[2].number = table.length
      })
      return this.controllerStat[2].number
    },
    numberPersonalInService: async function () { // GET NUMBER PERSONAL IN SERVICE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'administrative'),
        where('status', '==', 'inService')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.personalStat[0].number = table.length
      })
      return this.personalStat[0].number
    },
    numberPersonalOnLeave: async function () { // GET NUMBER PERSONAL ON LEAVE
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'administrative'),
        where('status', '==', 'onVacation')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        this.personalStat[1].number = table.length
      })
      return this.personalStat[1].number
    },
    async numberPersonalSuspended () { // GET NUMBER PERSONAL SUSPENDED
      const table = []
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'administrative'),
        where('status', '==', 'suspended')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        table.push(doc.data())
        // console.log(table)
        this.personalStat[2].number = table.length
      })
      return this.personalStat[2].number
    },
    async getAgentStats () { // GET ALL AGENT NUMBER
      const q = query(collection(firestore, 'agents'), where('agentFunction', '==', 'agent'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAgents.push(doc.data())
        this.numberAgentInService()
        this.numberAgentOnLeave()
        this.numberAgentSuspended()
      })
    },
    async getControllerStats () { // GET ALL CONTROLLER NUMBER
      const q = query(collection(firestore, 'agents'), where('agentFunction', '==', 'supervisor'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allControllers.push(doc.data())
        this.numberControllerInService()
        this.numberControllerOnLeave()
        this.numberControllerSuspended()
      })
    },
    async getDeployedEquipmentStats () { // GET ALL DEPLOYED EQUIPMENT NUMBER
      const q = query(collection(firestore, 'affected'), where('quantity', '>', 0))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.deployedEquipments = this.deployedEquipments + doc.data().quantity
      })
    },
    async getPersonalStats () { // GET ALL PERSONAL NUMBER
      const q = query(collection(firestore, 'agents'), where('agentFunction', '==', 'administrative'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allPersonal.push(doc.data())
        this.numberPersonalInService()
        this.numberPersonalOnLeave()
        this.numberPersonalSuspended()
      })
    },
    goNext () {
      this.$refs.carousel.next()
    },
    goNextSecond () {
      this.$refs.carousels.next()
    },
    goNextThird () {
      this.$refs.carouselss.next()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .name, .percent {
    color: $team_gray_title;
  }
  .carousel__slide {
    width: 46% !important;
  }
  .in-service {
    color: $team_green_stat;
    background: rgba(10, 231, 111, 0.15);
  }
  .on-leave {
    color: $team_orange;
    background: rgba(251, 167, 5, 0.15);
  }
  .suspended {
    color: $team_gray_title;
    background: rgba(79, 79, 79, 0.15);
  }
  .svg-fill {
    fill: transparent;
  }
</style>
