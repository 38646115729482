<template>
  <div class="dashboard">
    <div class="begin pb-12">
      <!-- HEADER POPUP -->
      <div class="header">
        <the-header :show-add-button="false" :show-file-button="false" :show-number="false" :show-search="true"/>
      </div>
      <!-- HEADER POPUP END -->
      <div class="body">
        <div class="statistics mb-4">
          <enterprise-stats/>
        </div>
        <div class="stat-second mt-4 flex justify-between">
          <div class="salary mr-3 w-2/3">
            <salary-stats/>
          </div>
          <div class="incident ml-3 w-1/3">
            <incident-stats/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import EnterpriseStats from '../../components/helper/dashboard/enterprise-stats'
import SalaryStats from '../../components/helper/dashboard/salary-stats'
import IncidentStats from '../../components/helper/dashboard/incident-stats'
export default {
  name: 'index',
  components: { IncidentStats, SalaryStats, EnterpriseStats, TheHeader }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
</style>
