<template>
  <div class="salary-stats">
    <div class="begin bg-white rounded-xl shadow p-8">
      <div class="stat-title flex justify-between">
        <div class="title flex items-center">
          <div class="chiffre mr-4">
            <div class="affaire text-2xl font-medium">{{ $t('turnOver') }}</div>
            <div class="Math.round flex items-center">
              <div class="color-1 mr-4 w-8 rounded-full h-2"></div>
              <div class="price">
                {{ new Intl.NumberFormat('FR', { style: 'currency', currency: 'XOF' }).format(getTurnOver) }}
              </div>
            </div>
          </div>
          <div class="chiffre ml-4">
            <div class="affaire text-2xl font-medium">{{ $t('wageCosts') }}</div>
            <div class="Math.round flex items-center">
              <div class="color-2 mr-4 w-8 rounded-full h-2"></div>
              <div class="price">
                {{ new Intl.NumberFormat('FR', { style: 'currency', currency: 'XOF' }).format(getWageCosts) }}
              </div>
            </div>
          </div>
        </div>
        <div class="this.date input-bloc flex items-center">
          <v-date-picker v-model="range" is-range :update-on-input="false" :popover="{ placement: 'bottom', visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  placeholder="31/01/2022"
                  class="this.dates-place text-sm px-2 py-1 w-24 focus:outline-none"
                />
                <div class="this.dates-place text-sm">-</div>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  placeholder="06/02/2022"
                  class="this.dates-place text-sm px-2 py-1 w-24 focus:outline-none"
                />
              </div>
            </template>
          </v-date-picker>
          <div class="icon ml-4">
            <icon :data="icons.iconDown" original width="10" height="10"/>
          </div>
        </div>
      </div>
      <div id="chart" class="charts mt-8">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import iconDown from '../../../assets/icons/ic_open_down.svg'
import { collection, getDocs, where } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'salary-stats',
  data () {
    return {
      icons: {
        iconDown
      },
      range: {},
      allMoney: [],
      allSalary: [],
      series: [
        {
          name: this.$t('turnOver'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: this.$t('wageCosts'),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chartOptions: {
        chart: {
          fontFamily: 'Rubik, sans-serif',
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors: ['#2A4574', '#FBA705'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        markers: {
          size: 1
        },
        grid: {
          borderColor: '#EBEBEB',
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          categories: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc']
        },
        yaxis: {
          tickAmount: 5
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'center'
        }
      },
      date: []
    }
  },
  created () {
    this.getMonthlyMoney()
    this.getMonthlySalary()
  },
  computed: {
    getTurnOver: function () { // GET CHIFFRE D'AFFAIRE Math.round
      let num = 0
      for (let i = 0; i < this.allMoney.length; i++) {
        num = num + Math.round(this.allMoney[i].information.monthlyPayment)
      }
      return num
    },
    getWageCosts: function () { // GET CHARGE SALARIALE Math.round
      let num = 0
      for (let i = 0; i < this.allSalary.length; i++) {
        num = num + Math.round(this.allSalary[i].salary)
      }
      return num
    }
  },
  methods: {
    chooseMonthName (value) {
      switch (value) {
      case 0:
        return 'Jan'
      case 1:
        return 'Fév'
      case 2:
        return 'Mar'
      case 3:
        return 'Avr'
      case 4:
        return 'Mai'
      case 5:
        return 'Jui'
      case 6:
        return 'Juil'
      case 7:
        return 'Aou'
      case 8:
        return 'Sep'
      case 9:
        return 'Oct'
      case 10:
        return 'Nov'
      case 11:
        return 'Déc'
      }
    },
    getMonthlyStats () {
      const vm = this
      const result = this.allMoney.reduce(function (r, a) {
        r[vm.chooseMonthName(new Date(a.createdAt).getMonth())] = r[vm.chooseMonthName(new Date(a.createdAt).getMonth())] || []
        r[vm.chooseMonthName(new Date(a.createdAt).getMonth())].push(a)
        return r
      }, Object.create(null))
      // console.log(result)

      for (const property in result) {
        // console.log(`${property}: ${result[property]}`)
        if (property.toString() === 'Jan') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[0] = num
        }
        if (property.toString() === 'Fév') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[1] = num
        }
        if (property.toString() === 'Mar') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[2] = num
        }
        if (property.toString() === 'Avr') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[3] = num
        }
        if (property.toString() === 'Mai') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[4] = num
        }
        if (property.toString() === 'Jui') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[5] = num
        }
        if (property.toString() === 'Juil') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[6] = num
        }
        if (property.toString() === 'Aou') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[7] = num
        }
        if (property.toString() === 'Sep') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[8] = num
        }
        if (property.toString() === 'Oct') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[9] = num
        }
        if (property.toString() === 'Nov') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[10] = num
        }
        if (property.toString() === 'Déc') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].information.monthlyPayment)
            // console.log(result[property][i].information.monthlyPayment)
          }
          this.series[0].data[11] = num
          // console.log(11, num)
        }
      }
    },
    getMonthlySalaryStats () {
      const vm = this
      const result = this.allSalary.reduce(function (r, a) {
        r[vm.chooseMonthName(new Date(a.createdAt).getMonth())] = r[vm.chooseMonthName(new Date(a.createdAt).getMonth())] || []
        r[vm.chooseMonthName(new Date(a.createdAt).getMonth())].push(a)
        return r
      }, Object.create(null))

      for (const property in result) {
        // console.log(`${property}: ${result[property]}`)
        if (property.toString() === 'Jan') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[0] = num
        }
        if (property.toString() === 'Fév') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[1] = num
        }
        if (property.toString() === 'Mar') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[2] = num
        }
        if (property.toString() === 'Avr') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[3] = num
        }
        if (property.toString() === 'Mai') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[4] = num
        }
        if (property.toString() === 'Jui') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[5] = num
        }
        if (property.toString() === 'Juil') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[6] = num
        }
        if (property.toString() === 'Aou') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[7] = num
        }
        if (property.toString() === 'Sep') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[8] = num
        }
        if (property.toString() === 'Oct') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[9] = num
        }
        if (property.toString() === 'Nov') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[10] = num
        }
        if (property.toString() === 'Déc') {
          let num = 0
          for (const i in result[property]) {
            num = num + Math.round(result[property][i].salary)
          }
          this.series[1].data[11] = num
        }
      }
    },
    async getMonthlyMoney () { // GET ALL SITE INFOS
      const querySnapshot = await getDocs(collection(firestore, 'sites'))
      querySnapshot.forEach((doc) => {
        this.allMoney.push(doc.data())
        // console.log(this.allMoney)
      })
      this.getMonthlyStats()
    },
    async getMonthlySalary () { // GET ALL AGENT INFOS
      const querySnapshot = await getDocs(collection(firestore, 'agents'),
        where('agentFunction', '!=', 'Administration centrale'))
      querySnapshot.forEach((doc) => {
        this.allSalary.push(doc.data())
        // console.log(this.allSalary)
      })
      this.getMonthlySalaryStats()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .affaire {
    color: $team_gray_title;
  }
  .color-1 {
    background: #2A4574;
  }
  .color-2 {
    background: #FBA705;
  }
  .price, .this.dates-place {
    color: #8E8EA9;
  }
  .svg-fill {
    fill: transparent;
  }
  .input-bloc:deep(.vc-container) {
    font-family: $font-default !important;
    .vc-day-content {
      font-weight: 400 !important;
    }
  }
</style>
