<template>
  <div class="incident-stats">
    <div class="begin bg-white shadow rounded-xl p-8">
      <div class="title text-2xl font-medium uppercase">{{ $t('incidentsMenu') }}</div>
      <div class="stats flex items-center">
        <div class="number font-medium text-xl mr-2">{{ allIncidents.length }}</div>
        <div class="percent font-medium text-xl hidden">- 1.7%</div>
        <div class="icon-down ml-4 hidden">
          <icon :data="icons.iconDown" original width="15" height="15"/>
        </div>
      </div>
      <div id="chart" class="charts mt-8">
        <apexchart type="pie" width="300" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import iconDown from '../../../assets/icons/ic_arrow_down.svg'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'incident-stats',
  data () {
    return {
      icons: {
        iconDown
      },
      allIncidents: [],
      treatedIncident: [],
      series: [100, 0],
      chartOptions: {
        chart: {
          fontFamily: 'Rubik, sans-serif',
          width: 380,
          type: 'pie'
        },
        colors: ['#35C25C', '#FFBB37'],
        labels: [this.$t('inWaiting'), this.$t('resolved')],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  created () {
    this.getAllIncidents()
    this.getAllTreatedIncidents()
  },
  methods: {
    async getAllIncidents () { // GET ALL INCIDENT
      const q = query(collection(firestore, 'courant_hands'),
        where('event', '==', 'Phi7eAYqBkEFolaATaxW'),
        orderBy('createdAt', 'desc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        this.allIncidents.push(doc.data())
        // console.log(this.allIncidents)
      })
    },
    async getAllTreatedIncidents () { // GET ALL TREATED INCIDENT
      const q = query(collection(firestore, 'courant_hands'),
        where('event', '==', 'Phi7eAYqBkEFolaATaxW'),
        where('status', '==', 'treated'),
        orderBy('createdAt', 'desc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        this.treatedIncident.push(doc.data())
        // console.log(this.treatedIncident)
        this.series[0] = ((this.allIncidents.length - this.treatedIncident.length) * 100) / this.allIncidents.length
        this.series[1] = (this.treatedIncident.length * 100) / this.allIncidents.length
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .title {
    color: $team_gray_title;
  }
  .percent {
    color: $team_gray_percent;
  }
</style>
